import { LinkExternal } from 'design/components/Typography/Typography'
import React from 'react'
import { Text, Lockup } from '@butcherbox/freezer'

const SourcingSectionBeef: React.FC<{ tabIndex?: number | undefined }> = ({
  tabIndex,
}) => {
  return (
    <Lockup>
      <Text variant="Body1Regular">
        Our cattle are humanely raised, 100% grass-fed, and free to roam on
        grassy pastures throughout their entire lives.
      </Text>
      <Text variant="Body1Regular">
        If you’re buying grass-fed beef at the grocery store, you might not be
        getting what you expect. Typically, grain-fed or grass-fed cattle start
        their lives on pasture but are later confined to feedlots where their
        diets can include grains. Grass-fed, grass-finished cattle—also known as
        100% grass-fed—are free to roam on pasture for their entire lives, not
        just while they’re calves.
      </Text>
      <Text variant="Body1Regular">
        We source the majority of our beef from Australia, where they’ve built
        an entire system on certifying pasture-raised meat. Australia has very
        high animal welfare standards as well as the climate and pastureland to
        allow cattle to spend their entire lives grazing. Get more details{' '}
        <LinkExternal
          href="https://support.butcherbox.com/hc/en-us/articles/360000094648-Can-you-tell-me-about-your-beef-"
          {...{ tabIndex }}
        >
          here.
        </LinkExternal>
      </Text>
    </Lockup>
  )
}

export default SourcingSectionBeef
