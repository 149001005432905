import React from 'react'
import { Text, Lockup, List, ListItem, IconCheck } from '@butcherbox/freezer'
const gapBullets = ['No herbicides or pesticides ', 'No GMOS – ever ']
const CheckIcon = <IconCheck customColor={{ base: 'spicedCrimson' }} />

const SourcingSectionTurkey: React.FC<{
  tabIndex?: number | undefined
}> = () => {
  return (
    <Lockup>
      <Text variant="Body1Regular">
        Our bison are humanely raised, 100% grass-fed, and sourced here in the
        United States, where they are free to roam on over 100 square miles of
        grassy prairie throughout their entire lives.
      </Text>
      <Text variant="Body1Regular">
        Often referred to in North America as buffalo, our bison are raised
        using sustainable and regenerative grazing practices.
      </Text>
      <Text variant="Subhead2">
        What is meant by sustainable and regenerative grazing practices?
      </Text>{' '}
      <Text variant="Body1Regular">
        This references the practice of building soil health and grazing in a
        way that supports human, animal, and environmental health, food system
        resilience, farm profitability, and benefit for the community.
      </Text>
      <Text variant="Body1Regular">
        The bison we send you graze on natural organic pastures with:{' '}
      </Text>
      <List>
        {gapBullets.map((bullet, i) => (
          <ListItem icon={CheckIcon} key={`bison-${i}`}>
            {bullet}
          </ListItem>
        ))}
      </List>
      <Text variant="Body1Regular">
        They eat mixed tall grass and shortgrass prairie along with clean water
        and non-GMO wild pastures to ensure high-quality, delicious tasting meat
        and happy, healthy bison.
      </Text>{' '}
    </Lockup>
  )
}

export default SourcingSectionTurkey
