import React from 'react'
import { Text, Lockup } from '@butcherbox/freezer'

const SourcingSectionPork: React.FC = () => {
  return (
    <Lockup>
      <Text variant="Body1Regular">
        We believe in better from the ground up—and that means reducing slatted
        floors and eliminating gestation crates and farrowing crates for hogs.
        Our hogs are raised on pastures or in hoop barns, with their natural
        tendencies, safety, and comfort in mind.
      </Text>

      <Text variant="Body1Regular">
        Many of our pork products are sourced from pigs with heritage-breed
        lineage. These breeds include (but are not limited to) Duroc, Berkshire,
        and Chester White. Their natural, “old-world” genetics mean that during
        their slow maturation process, they develop the fine marbling and great
        marbling that mother nature intended them to have.
      </Text>
      <Text variant="Body1Regular">
        Our hogs are fed an all-vegetarian diet and are raised with traditional,
        sustainable farming methods.
      </Text>
    </Lockup>
  )
}

export default SourcingSectionPork
