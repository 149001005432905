import { Link as GatsbyLink } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import { Text, Box, Lockup, ButtonLink } from '@butcherbox/freezer'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'
import React from 'react'
import useProtectedPage from '~/cms/hooks/useProtectedPage'
import { CTA_UNAUTHENTICATED_DEFAULT } from '~/data/constants'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import { TRY_BB_URL } from '~/routes/constants'
import SourcingBeef from '~/sections/Sourcing/Beef'
import SourcingBison from '~/sections/Sourcing/Bison'
import SourcingChicken from '~/sections/Sourcing/Chicken'
import SourcingTurkey from '~/sections/Sourcing/Turkey'
import SourcingPork from '~/sections/Sourcing/Pork'
import SourcingSeafood from '~/sections/Sourcing/Seafood'
import SourcingSection from '~/sections/Sourcing/SourcingSection'
import type { SourcingSectionText } from '~/sections/Sourcing/SourcingSection.types'
import SourcingWarehouse from '~/sections/Sourcing/Warehouse'
import { cleanJsonStrings } from '~/utils/unicode'
import * as Styles from './Sourcing.css'
import clsx from 'clsx'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'
import { JOIN_586_VARIANTS } from '~/types/join-586-bypass-get-started'
import useSetCartOffer from '~/hooks/useSetCartOffer'

const sectionText: SourcingSectionText[] = [
  {
    title: '100% Grass-Fed, Grass-Finished beef',
    description: 'Grass is greener',
    bullets: [
      '100% grass-fed and grass-finished',
      'Humanely raised',
      'Never given antibiotics or added hormones ever',
      'Source of omega-3s, vitamins, and minerals',
    ],
    expandCtaText: 'More about our beef',
    pictureName: 'cows',
    text: <SourcingBeef />,
  },
  {
    title: 'Pork Raised Crate-Free',
    description: 'Taking a stand',
    bullets: [
      'Humanely raised*',
      'All vegetarian feed**',
      'Never given antibiotics or added hormones ever',
    ],
    disclaimerText: [
      '*Without gestation or farrowing crates',
      '**Except milk proteins',
    ],
    expandCtaText: 'More about our pork',
    pictureName: 'pigs',
    text: <SourcingPork />,
  },
  {
    title: 'Free-Range Organic Chicken',
    description: 'Opening Doors',
    bullets: [
      'Free-range',
      'USDA certified organic (with certified organic feed)',
      'Global Animal Partnership (GAP 3) certified',
      'Humanely raised',
      'Never given antibiotics or added hormones ever*',
    ],
    expandCtaText: 'More about our chicken',
    pictureName: 'chickens',
    text: <SourcingChicken />,
    disclaimerText:
      '*Federal regulations prohibit the use of hormones or steroids in poultry',
  },
  {
    title: 'Wild-Caught Seafood',
    description: 'For generations to come',
    bullets: [
      'Wild-caught',
      'Sustainably harvested',
      'No artificial coloring',
      'No additives',
    ],
    expandCtaText: 'More about our seafood',
    pictureName: 'seafood',
    text: <SourcingSeafood />,
  },
  {
    title: 'Free-Range* Turkey',
    description: 'Raised Naturally',
    bullets: [
      'Free-range*',
      'All-natural** young turkey',
      'Never given antibiotics or added hormones ever†',
      'No preservatives',
      'Complete traceability to the farm',
      'Hatched, raised and harvested in the USA',
    ],
    disclaimerText: [
      '*Confinement may occur if conditions pose a welfare risk',
      '**All-natural: no basting ingredients, minimally processed, no artificial ingredients',
      '†Federal regulations do not permit the use of hormones in poultry',
    ],
    expandCtaText: 'More about our turkey',
    pictureName: 'turkey',
    text: <SourcingTurkey />,
  },
  {
    title: '100% Grass-fed Bison',
    description: 'Free to Roam',
    bullets: [
      '100% grass-fed ',
      'Humanely raised ',
      'Never given antibiotics or added hormones ever* ',
    ],
    disclaimerText:
      '*Federal regulations do not permit the use of growth hormones or artificial stimulants when raising bison ',
    expandCtaText: 'More about our bison',
    pictureName: 'bison',
    text: <SourcingBison />,
  },
  {
    title: 'Trusted Partners and Fair Labor Practices',
    description: 'The right process',
    bullets: [],
    expandCtaText: '',
    pictureName: 'warehouse',
    text: <SourcingWarehouse />,
  },
]

export default function Sourcing() {
  const [currentImage, setCurrentImage] = React.useState(
    sectionText[0].pictureName
  )
  const parentRef = React.useRef<HTMLElement>()

  const data = cleanJsonStrings(
    useStaticQuery(graphql`
      query {
        pageData: contentfulProtectedPage(pageId: { eq: "sourcing" }) {
          ...ProtectedPage
        }
        hero: file(relativePath: { eq: "sourcing-hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        cows: file(relativePath: { eq: "sourcing-cows.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        pigs: file(relativePath: { eq: "sourcing-pigs.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        chickens: file(relativePath: { eq: "sourcing-chickens.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        bison: file(relativePath: { eq: "sourcing-bison.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        turkey: file(relativePath: { eq: "sourcing-turkey.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        seafood: file(relativePath: { eq: "sourcing-seafood.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        warehouse: file(relativePath: { eq: "sourcing-warehouse.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        betterTogether: file(
          relativePath: { eq: "sourcing-better-together.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  )

  const { header } = useProtectedPage(data?.pageData)

  const { cartDealUrl } = useSetCartOffer()
  const [shouldBypassGetStarted] = useOptimizelyExperiment(
    'join-586__bypassing-get-started'
  ) as [JOIN_586_VARIANTS, null, null]

  return (
    <UnauthenticatedLayout cta={header?.cta?.content}>
      <BackgroundImage
        className={clsx(Styles.HeroBgImage)}
        fluid={data.hero.childImageSharp.fluid}
      >
        <Box className={Styles.HeroContents}>
          <Lockup className={Styles.HeroLockup}>
            <Text color="white" variant="DisplayOne">
              We believe in doing what's right
            </Text>
            <ButtonLink
              component={GatsbyLink}
              data={{ what: 'button' }}
              target="_self"
              to={
                shouldBypassGetStarted &&
                shouldBypassGetStarted === JOIN_586_VARIANTS?.BYPASS_GET_STARTED
                  ? cartDealUrl
                  : TRY_BB_URL
              }
              variant="primary"
            >
              {CTA_UNAUTHENTICATED_DEFAULT}
            </ButtonLink>
          </Lockup>
        </Box>
      </BackgroundImage>
      <Box
        alignItems="center"
        background="ivory"
        display="flex"
        flexDirection="column"
        px={24}
      >
        <Lockup className={Styles.OurSourcingLockup}>
          <Text component="h2" variant="Subhead1">
            Our Sourcing
          </Text>
          <Text component="p" variant="Thematic">
            We partner with people who are dedicated to doing the right
            thing—never cutting corners, and always looking for ways to improve.
            Discover why we’re the trusted source of high-quality protein for
            families across the country.
          </Text>
        </Lockup>
      </Box>
      <Box className={Styles.SourcingGrid} ref={parentRef}>
        <Box alignItems="center" display="flex" flexDirection="column">
          {sectionText.map((section) => {
            return (
              <SourcingSection
                key={section.description}
                mobileImage={data[section.pictureName].childImageSharp.fluid}
                parentRef={parentRef}
                sectionInfo={section}
                setCurrentImage={setCurrentImage}
              />
            )
          })}
        </Box>
        <Box className={Styles.SourcingImage}>
          <Box position="relative">
            {sectionText.map(({ pictureName }) => {
              const boxClasses = [Styles.TransitionBox]
              if (currentImage === pictureName) {
                boxClasses.push(Styles.TransitionBoxIsVisible)
              }
              return (
                <Box className={clsx(boxClasses)} key={pictureName}>
                  <Image
                    fluid={{
                      ...data[pictureName].childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
      <Box background="ivory" px={{ mobile: 16, tablet: 24 }}>
        <Box className={Styles.FooterHero}>
          <Box className={Styles.FooterImage}>
            <Image
              fluid={data.betterTogether.childImageSharp.fluid}
              style={{ height: '100%', width: '100%' }}
            />
          </Box>
          <Box className={Styles.FooterContent}>
            <Lockup textAlign="center">
              <Text component="h2" variant="DisplayOne">
                We're better together
              </Text>
              <Text component="h3" variant="H2Bold">
                Discover why we’re the trusted source of high-quality protein
                for families across the country.
              </Text>
              <ButtonLink
                component={GatsbyLink}
                data={{ what: 'button' }}
                target="_self"
                to={
                  shouldBypassGetStarted &&
                  shouldBypassGetStarted ===
                    JOIN_586_VARIANTS?.BYPASS_GET_STARTED
                    ? cartDealUrl
                    : TRY_BB_URL
                }
                variant="primary"
              >
                {CTA_UNAUTHENTICATED_DEFAULT}
              </ButtonLink>
            </Lockup>
          </Box>
        </Box>
      </Box>
    </UnauthenticatedLayout>
  )
}
