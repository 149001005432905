import { LISimpleCheck, List } from 'design/components/List/List'
import { LinkExternal } from 'design/components/Typography/Typography'
import React from 'react'
import { Text, Lockup, rem } from '@butcherbox/freezer'

const gapBullets = [
  'Health and productivity',
  'Natural living',
  'Emotional wellbeing',
]

const SourcingSectionChicken: React.FC<{ tabIndex?: number | undefined }> = ({
  tabIndex,
}) => {
  return (
    <Lockup>
      <Text variant="Body1Regular">
        Everyone can benefit from a little fresh air and natural light—chickens
        included. That’s why we work with farmers who raise their chickens
        without cages, crates, or crowding.
      </Text>
      <Text variant="Body1Regular">
        Free-range means chickens have access to the outdoors seasonally. Our
        farmers work to develop enhancements for chicken enrichment indoors and
        insert windows within barns.
      </Text>
      <Text variant="Body1Regular">
        The Global Animal Partnership (GAP) has created a meaningful animal
        welfare labeling program, verified by audits on participating farms, in
        order to influence the food industry, raise consumer expectations, and
        create a sustainable environment.
      </Text>
      <Text variant="Body1Regular">
        GAP defines animal welfare as three overlapping components that,
        together with good management and genetics, contribute to good farm
        animal welfare:
      </Text>
      <List mb={rem(31)} mt={rem(27)}>
        {gapBullets.map((bullet, idx) => {
          return (
            <LISimpleCheck key={idx}>
              <strong>{bullet}</strong>
            </LISimpleCheck>
          )
        })}
      </List>
      <Text variant="Body1Regular">
        These three aspects are defined within the GAP certification ranges,
        which start at 1 (being the base certification). Our chickens have a GAP
        3 certification, which means they meet all of the criteria needed for
        GAP 1, 2, and 3:
      </Text>
      <List mt={rem(35)}>
        <LISimpleCheck>
          <Text variant="Body1Regular">
            <strong>GAP 1:</strong> No cages, no crates, no crowding. Chickens
            live in a stationary housing structure, are provided space to
            express natural behavior and are given access to added enrichments.
          </Text>
        </LISimpleCheck>
        <LISimpleCheck>
          <Text variant="Body1Regular">
            <strong>GAP 2:</strong> Everything in GAP 1 + Enriched environment.
            Chickens live in an indoor environment with at least two different
            types of added enrichments as well as natural light.
          </Text>
        </LISimpleCheck>
        <LISimpleCheck>
          <Text variant="Body1Regular">
            <strong>GAP 3:</strong> Everything in GAP 1 &amp; 2 + Outdoor
            access. Chickens have seasonal outdoor access, more space
            requirements (in and out), and outdoor shade and enrichments. Get
            more details{' '}
            <LinkExternal
              href="https://support.butcherbox.com/hc/en-us/articles/115015793928-Can-you-tell-me-about-your-chicken-"
              {...{ tabIndex }}
            >
              here.
            </LinkExternal>
          </Text>
        </LISimpleCheck>
      </List>
    </Lockup>
  )
}

export default SourcingSectionChicken
