import React from 'react'
import { Text, Lockup, List, ListItem, IconCheck } from '@butcherbox/freezer'

const gapBullets = [
  'Our turkeys are thoughtfully cared for on the farm every step of the way, from the hatchery to the pasture.',
  'These farms either grow their own fresh feed including grains, corn, and soybeans or work with local feed mills to make custom feed for their flocks.',
  'The white-feathered turkeys are grown on fresh straw or pine shavings, which aids in the cleanliness of the live turkey barns, for instance, giving the farmers of this independent breed a leg-up in the industry.',
  'Our birds run around in their open pens and are given various types of enrichments (toys) inside, which promote interaction and motion throughout the day.',
  'Our birds are given plenty of access to the outdoors with lots of fresh air, grass, and sunshine*.',
]
const CheckIcon = <IconCheck customColor={{ base: 'spicedCrimson' }} />
const SourcingSectionTurkey: React.FC = () => {
  return (
    <Lockup>
      <Text variant="Body1Regular">
        Sourced here in the United States, our turkeys are raised on
        multi-generational family-run farms that are always mindful of the
        treatment of the animals, farmers, environment, and what it means to
        believe in better.
      </Text>

      <Text variant="Body1Regular">
        The turkeys on these farms are grown differently than commercial
        operators:
      </Text>
      <List>
        {gapBullets.map((bullet, i) => (
          <ListItem icon={CheckIcon} key={`turkey-${i}`}>
            {bullet}
          </ListItem>
        ))}
      </List>
      <Text variant="Body1Regular">
        Our turkeys are farmed with a focus on broad-breasted turkeys and a deep
        commitment to raising turkeys the right way to produce high-quality meat
        with incredible flavor and value. We work with our farmers to strive to
        be a part of the gold standard in breeding excellence and practices that
        support healthy animals, humans, and the environment.
      </Text>
    </Lockup>
  )
}

export default SourcingSectionTurkey
