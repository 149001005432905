import { LICircleCheck, List } from 'design/components/List/List'
import {
  rem,
  Box,
  Lockup,
  Text,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@butcherbox/freezer'
import Image from 'gatsby-image'
import React from 'react'
import Disclaimer from '~/cms/components/disclaimer'
import { useVisible } from '~/hooks/useVisible'
import type * as Types from './SourcingSection.types'
import * as Styles from './SourcingSection.css'
/**
 * A sourcing section contains a text area and a collapsible hidden text area
 * that communicates back to a parent component when it is in the viewport
 */
export default function SourcingSection({
  mobileImage,
  sectionInfo,
  setCurrentImage,
  parentRef,
  accordionOpen = false,
}: Types.SourcingSectionProps) {
  const {
    description,
    title,
    expandCtaText,
    pictureName,
    bullets,
    text,
    disclaimerText,
  } = sectionInfo

  const [targetRef, visible] = useVisible({
    root: parentRef.current,
    rootMargin: '-30% 0px -40% 0px',
  })

  const [tabIndex, setTabIndex] = React.useState<undefined | -1>(-1)

  const onChangeHandler = React.useCallback((currentState: boolean) => {
    setTabIndex(currentState ? undefined : -1)
  }, [])

  React.useEffect(() => {
    if (visible) {
      setCurrentImage(pictureName)
    }
  }, [pictureName, setCurrentImage, visible])

  const renderDisclaimer = (disclaimerText) => {
    if (Array.isArray(disclaimerText)) {
      return disclaimerText.map((text, i) => (
        <Disclaimer key={`disclaimer-${i}`}>{text}</Disclaimer>
      ))
    } else {
      return <Disclaimer>{disclaimerText}</Disclaimer>
    }
  }
  return (
    <Box
      className={Styles.sourcingSection}
      data-testid="sourcingSection"
      ref={targetRef as React.RefObject<HTMLElement>}
    >
      <Box display={{ mobile: 'block', tablet: 'none' }}>
        <Image
          fluid={mobileImage}
          style={{
            height: rem(248),
            margin: `0 auto ${rem(60)}`,
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" px={24}>
        <Box marginBottom={24}>
          <Lockup className={Styles.Headlines}>
            <Text component="h2" variant="Eyebrow">
              {description}
            </Text>
            <Text component="h3" variant="H1Bold">
              {title}
            </Text>
          </Lockup>
        </Box>

        {bullets && bullets.length ? (
          <List as="ul" mb={rem(24)} pl={rem(16)}>
            {bullets.map((bulletText, idx) => {
              return <LICircleCheck key={idx}>{bulletText}</LICircleCheck>
            })}
          </List>
        ) : null}
        {expandCtaText ? (
          <>
            <Accordion
              data-testid="accordion"
              defaultOpen={accordionOpen}
              id={expandCtaText.replace(/ /g, '_')}
              onChange={onChangeHandler}
            >
              <AccordionSummary>{expandCtaText}</AccordionSummary>
              <AccordionDetails>
                {React.cloneElement(text, { tabIndex })}
              </AccordionDetails>
            </Accordion>
            {disclaimerText && renderDisclaimer(disclaimerText)}
          </>
        ) : (
          text
        )}
      </Box>
    </Box>
  )
}
