import { LinkExternal } from 'design/components/Typography/Typography'
import React from 'react'
import { Text, Lockup } from '@butcherbox/freezer'

const SourcingSectionSeafood: React.FC<{ tabIndex?: number | undefined }> = ({
  tabIndex,
}) => {
  return (
    <Lockup>
      <Text variant="Body1Regular">
        We believe our great-grandchildren should have the chance to enjoy
        wild-caught seafood. That’s why all the seafood we offer is sustainably
        harvested.
      </Text>
      <Text variant="Subhead2">Our Salmon</Text>
      <Text variant="Body1Regular">
        Our salmon is wild-caught and sustainably harvested. When we deliver
        anything that’s wild-caught, it’s important to us that it’s sustainably
        harvested and contributes to the overall health of the ecosystem. It’s
        why we partner with people who uphold strict fishing and handling
        practices to guarantee an unprecedented level of quality and
        sustainability. Every year, the best sockeye salmon on the planet make
        their way to the pristine waters of Bristol Bay, Alaska. Fishermen take
        their boats and head out to catch these fish in their natural habitat.
        Get more details{' '}
        <LinkExternal
          href="https://support.butcherbox.com/hc/en-us/articles/360047484694-Can-you-tell-me-about-your-seafood-"
          {...{ tabIndex }}
        >
          here.
        </LinkExternal>
      </Text>
      <Text variant="Subhead2">Our Scallops</Text>
      <Text variant="Body1Regular">
        Our sea scallops are all-natural with no added water or chemicals.
        They’re caught in the cold water of Georges Bank, landed in New Bedford,
        Massachusetts and are traceable using the technology of Legit Fish.
        They’re sold “dry,” which means there are no added chemicals to make the
        scallops retain water and overcook. Get more details{' '}
        <LinkExternal
          href="https://support.butcherbox.com/hc/en-us/articles/360047484694-Can-you-tell-me-about-your-seafood-"
          {...{ tabIndex }}
        >
          here.
        </LinkExternal>
      </Text>
      <Text variant="Subhead2">Our Lobster</Text>
      <Text variant="Body1Regular">
        Our lobster is wild-caught by independent harvesters in the cold, clean
        waters of the North Atlantic. We partner with a family-run business off
        the coast of Maine to bring our members delicious, high-quality
        lobster—sustainably. Learn their story{' '}
        <LinkExternal
          href="https://support.butcherbox.com/hc/en-us/articles/360047484694-Can-you-tell-me-about-your-seafood-"
          {...{ tabIndex }}
        >
          here.
        </LinkExternal>
      </Text>
      <Text variant="Subhead2">Our Cod, Halibut &amp; Sablefish</Text>
      <Text variant="Body1Regular">
        Our wild-caught cod, halibut, and sablefish are sustainably harvested in
        the pristine waters of the Gulf of Alaska and the Bering Sea. We’re
        proud to partner with a fishery that’s managed under strict standards to
        maintain long-term sustainability, helping to ensure access to
        delicious, wild-caught seafood for generations to come.
      </Text>
      <Text variant="Subhead2">Our Shrimp</Text>
      <Text variant="Body1Regular">
        Our wild-caught shrimp come from the Gulf of Mexico and are fully
        traceable using the technology of Legit Fish. They come deshelled with
        the tail off and deveined for your convenience. Get more details{' '}
        <LinkExternal
          href="https://support.butcherbox.com/hc/en-us/articles/360047484694-Can-you-tell-me-about-your-seafood"
          {...{ tabIndex }}
        >
          here
        </LinkExternal>
        .
      </Text>
    </Lockup>
  )
}

export default SourcingSectionSeafood
