import { LICircleCheck, List } from 'design/components/List/List'
import React from 'react'
import { Text, Lockup, rem } from '@butcherbox/freezer'

const SourcingSectionWarehouse: React.FC = () => {
  return (
    <Lockup>
      <Text variant="Body1Regular">
        We believe in better every step of the way. That includes working with
        people we trust at meat processing facilities with the highest standards
        for quality.
      </Text>
      <List pl={rem(16)} pt={rem(16)}>
        <LICircleCheck>
          <div>
            <Text variant="H4Bold">Trusting our partners</Text>
            <Text variant="Body1Regular">
              Our partners' operating methods are inspired by Dr. Temple
              Grandin, a member of the Humane Farm Animal Care Program's
              scientific committee. Their facilities are routinely inspected and
              approved by the USDA or a comparable agency.
            </Text>
          </div>
        </LICircleCheck>
        <LICircleCheck>
          <div>
            <Text variant="H4Bold">Honoring our team</Text>
            <Text variant="Body1Regular">
              We believe in fair labor practices. In line with our family and
              community values, we seek partners who treat their employees as
              part of a team—and we’re ever thankful for that team. The folks in
              our facilities make up a crucial part of our supply chain. Without
              them, keeping food on our members’ tables would be impossible.
            </Text>
          </div>
        </LICircleCheck>
      </List>
    </Lockup>
  )
}

export default SourcingSectionWarehouse
